.domain-search-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.input-wrapper {
  display: flex;
  align-items: center;
}

input {
  width: 500px;
  height: 60px;
  padding: 10px 20px;
  border: none;
  background-color: #EBEBEB;
  font-size: 18px;
  color: #333;
  outline: none;
  border-radius: 0;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

input::placeholder {
  font-weight: bold; /* Make placeholder text bold */
  color: #444444;
}

button {
  width: 175px;
  height: 60px;
  background-color: #39828A;
  border: none;
  color: #FFFFFF;
  font-size: 18px;
  cursor: pointer;
  margin-left: 0;
  border-radius: 0;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  font-weight: bold; /* Make button text bold */
}

button:hover {
  background-color: #2c6b6a; /* Slightly darker shade for hover */
}

.error-input {
  border: 2px solid red;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

.top-border {
  background-color: #A36848; /* Border color */
  padding: 15px; /* Adjust padding as needed */ 
}

.title {
  font-family: 'Nosifer', cursive;
  font-size: 36px; /* Adjust the size as needed */
  color: #FFFFFF; /* White font color */
  margin: 0; /* Remove default margins */
}

form {
  margin-top: 40px; /* Adjust the margin as needed */
}

@media (max-width: 768px) {
  input {
    width: 90%; /* Adjust as needed for mobile */
    max-width: 200px; /* Prevent it from becoming too wide on large mobile screens */
    font-size: 16px;
  }

  .top-border {
    max-width: 100%;
  }

  .title {
    font-size: 28px; /* Adjust as needed for mobile */
  }

  button {
    width: 90%; /* Adjust as needed for mobile */
    max-width: 100px; /* Prevent it from becoming too wide on large mobile screens */
    font-size: 16px;
  }

  /* Additional mobile-specific styles */
}